


@media (prefers-color-scheme: dark) {
    
    div {
      color: white;
    }
    
   
}
