/* fonts */

@font-face {
    font-family: 'National Park';
    src: url('../fonts/NationalPark-VariableVF.woff2') format('woff2'),
    url('../fonts/NationalPark-VariableVF.woff') format('woff'),
    url('../fonts/NationalPark-VariableVF.ttf') format('truetype');
    font-weight: 100 900; /* Use this range if it's a variable font */
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'DM Mono';
  src: url('../fonts/DMMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DM Mono';
  src: url('../fonts/DMMono-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DM Mono';
  src: url('../fonts/DMMono-Medium.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DM Mono';
  src: url('../fonts/DMMono-MediumItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}


/* Global styling */

:root {
    --color-background: #DED5D3;
    --color-neutral-primary: #EEE9E7;
    --color-neutral-secondary: #C9BAB6;
    
    --color-dark-background: #292727;
    --color-dark-neutral-primary: #363230;
    --color-dark-neutral-secondary: #403B3A;
    
    --color-card: #EEE9E7;
    --color-stroke: #C9BAB6;
    
    --color-dark-card: #363230;
    --color-dark-stroke: #403B3A;
    
    
}


html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-background) !important;
}




h1 {
  font-family: 'National Park';
  font-style: normal;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  color: var(--color-dark-neutral-primary);
}

h1.alternate {
  color: var(--color-neutral-primary);
  -webkit-text-stroke: 15px var(--color-dark-neutral-primary);
  paint-order: stroke fill;
}

h2 {
  font-family: 'National Park';
  font-style: normal;
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 2.8rem;
  color: var(--color-dark-neutral-primary);
}

h2.alternate {
    font-size: 2.3rem;
  color: var(--color-neutral-primary);
  -webkit-text-stroke: .75rem var(--color-dark-neutral-primary);
  paint-order: stroke fill;
}

h3 {
  font-family: 'National Park';
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: var(--color-dark-neutral-primary);
}

h3.alternate {
    font-size: 1.6rem;
  color: var(--color-neutral-primary);
  -webkit-text-stroke: .6rem var(--color-dark-neutral-primary);
  paint-order: stroke fill;
}

p {
  font-family: 'National Park';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--color-dark-neutral-primary);
}

/* Header stuff */



.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  padding: 2rem 2rem 1rem 2rem;
}

.headerLeft,
.headerMiddle,
.headerRight {
  display: flex;
  align-items: center;
}



.header-section {
  flex: 1;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.headerLogo {
  width: 10vw;
  padding-left: 100px;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 15px;
}

.playButton {
  text-decoration: none;
}

.playButtonContainer {
  background-color: var(--color-stroke);
  padding: .25rem 1rem;
  border-radius: 20px;
  cursor: pointer;
}

.playButtonContainer h3 {
    color: var(--color-dark-background);
margin: 0;
padding: 0;
font-size: 1.3rem;
}



@media (prefers-color-scheme: dark) {
    html, body {
        background-color: var(--color-dark-background) !important;
    }
    
    h1 {
      color: var(--color-neutral-primary);
    }

    h1.alternate {
      color: var(--color-dark-neutral-primary);
      -webkit-text-stroke: 15px var(--color-neutral-primary);
      paint-order: stroke fill;
    }

    h2 {
        color: var(--color-neutral-primary);
    }

    h2.alternate {
        color: var(--color-dark-neutral-primary);
        -webkit-text-stroke: .75rem var(--color-neutral-primary);
      paint-order: stroke fill;
    }

    h3 {
        color: var(--color-neutral-primary);
    }
    
    h3.alternate {
        color: var(--color-dark-neutral-primary);
        -webkit-text-stroke: .75rem var(--color-neutral-primary);
      paint-order: stroke fill;
    }

    p {
        color: var(--color-neutral-primary);
    }
    
    .playButtonContainer {
      background-color: var(--color-dark-stroke);
      padding: .25rem 1rem;
      border-radius: 20px;
      cursor: pointer;
    }

    .playButtonContainer h3 {
        color: var(--color-background);
    margin: 0;
    padding: 0;
    font-size: 1.3rem;
    }
}






/* Mobile responsiveness */

@media (max-width: 1000px) {
    
    .header {
        padding-bottom: .5rem;
    }
    
    .headerLogo {
width: 35vw;
    }
    
    h1 {
    }
    
    h2 {
        font-size: 1.3rem;
    }
    
    h2.alternate {
        font-size: 1.3rem;
        -webkit-text-stroke: 7px var(--color-dark-neutral-primary);

    }
    
    h3 {
        font-size: .9rem;
    }
    
    h3.alternate {
        font-size: .9rem;
        line-height: 1rem;
        -webkit-text-stroke: 3px var(--color-dark-neutral-primary);

    }
    
    p {
        font-size: .7rem;
        line-height: .8rem;
    }
    
}


@media (prefers-color-scheme: dark) and (max-width: 768px) {
    
    h1.alternate {
      color: var(--color-dark-neutral-primary);
      -webkit-text-stroke: 15px var(--color-neutral-primary);
      paint-order: stroke fill;
    }

    h2 {
        color: var(--color-neutral-primary);
    }

    h2.alternate {
        color: var(--color-dark-neutral-primary);
        -webkit-text-stroke: 6px var(--color-neutral-primary);
      paint-order: stroke fill;
    }

    h3 {
        color: var(--color-neutral-primary);
    }
    
    h3.alternate {
        color: var(--color-dark-neutral-primary);
        -webkit-text-stroke: 3px var(--color-neutral-primary);
      paint-order: stroke fill;
    }

  
}
