#root {
    overflow-x: hidden;
}

.homeUI {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--color-background);
}

.featuredBoardsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45vh;
    justify-content: center;
    position: relative; /* Needed for pseudo-elements to position correctly */
}

/* Add the left and right gradient fades */
.featuredBoardsContainer::before,
.featuredBoardsContainer::after {
    content: '';
    position: absolute;
    top: 0;
    width: 10%; /* Adjust width to control how much of the edges fade */
    height: 120%;
    z-index: 2; /* Place it above the content */
    pointer-events: none; /* Ensure it's not interactive */
}

/* Left gradient fade */
.featuredBoardsContainer::before {
    left: 0;
    background: linear-gradient(to right, var(--color-background) 10%, transparent 100%);
}

/* Right gradient fade */
.featuredBoardsContainer::after {
    right: 0;
    background: linear-gradient(to left, var(--color-background) 10%, transparent 100%);
}

.flickity-viewport{
    width: 100%;
}

.featuredBoard {
    position: relative; /* Make this relative to position the pseudo-element */
    width: 60vw;
    height: 40vh;
    padding: 1rem;
    background-size: cover;
    background-position: center;
    justify-content: flex-start;
    text-align: left;
    overflow: hidden;
    border-radius: 15px;
    align-content: center;
    margin: 1rem;
}

.featuredBoard::before {
    content: '';
    position: absolute;
    top: -25%;
    left: -40%;
    width: 115%; /* Cover the left half */
    height: 160%;
    background: inherit; /* Inherit the background from the parent */
    filter: blur(100px); /* Adjust the blur strength */
    z-index: 0; /* Place it behind the content */
}

.featuredHeader {
    padding-top: 7vh;
    position: relative;
    z-index: 1; /* Ensure it appears above the blur */
    width: 55%; /* Restrict to 60% width */
    margin-left: 2rem; /* Add left margin */
    text-align: left; /* Ensure the text is left-aligned */
}

.featuredDesc {
    position: relative;
    z-index: 1; /* Ensure it appears above the blur */
    width: 40%; /* Restrict to 60% width */
    margin-left: 2rem; /* Add left margin */
    text-align: left; /* Ensure the text is left-aligned */
}


.sitebodyContainer {
    display: flex;
    flex-direction: column; /* Ensures all child elements are stacked */
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
    width: 100%;
    padding: 0; /* Remove any extra padding that might shift content */
    margin: 0; /* Remove any extra margins that could affect centering */
    padding: 2rem;
}

.intro {
    justify-content: center;
    display: block; /* Ensure it behaves like a block-level element */
    padding: 4rem 1rem;
}

.intro h2 {
    padding: 2rem;
}



.boardSectionContainer {
    padding: 1em;
    width: 100%;
    position: relative; /* Needed for pseudo-elements to position correctly */
}

.boardSectionContainer .flickity-viewport {
    width: 100%;
    height: 40vh;
    position: relative;
}

.boardSection {
    position: relative;
    width: 20vw;
    height: 20vh;
    margin: 1rem;
    border-radius: 15px;
    background-size: cover;
    background-position: center top;
    overflow: hidden; /* Ensures background images stay within the rounded rectangle */
}

.boardSection::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%; /* Adjust to be the bottom 20% */
    backdrop-filter: blur(50px);
    background-color: rgba(0, 0, 0, 0.2); /* Optional: to help the blur effect stand out */
    z-index: 1; /* Ensure it's visible */
}

.placeholder {
    width: 10vw;

}

.placeholder::before,
.placeholder::after {
  content: none;
}

.sectionTitle {
    width: 100%;
    text-align: left;
    padding-left: 2rem;
    padding-top: 1rem;

}



.boardTitle {
    position: absolute;
    bottom: -7px;
    width: 100%;
    text-align: center;
    z-index: 2; /* Ensure text is above the background */
}




@media (max-width: 800px) {
    
    .mainUI {
        padding: .5rem;
    }
    
    .featuredBoardsContainer {
        height: 35vh;
        margin-top: 1em;
    }
    
    
    .boardSection {
        position: relative;
        width: 40vw;
        height: 30vh;
        margin: .5rem;

    }
    
    .boardSection::after {
        height: 30%;}
    
    
    .featuredBoard {
height: 30vh;
    padding: 0px 5px 0px 5px;
    margin: .5rem;

  }
    
    .featuredBoard::before {
        content: '';
        position: absolute;
        top: -25%;
        left: -40%;
        width: 155%; /* Cover the left half */
        height: 160%;
    }
    
    .featuredHeader {
        font-size: 1.5em;
        line-height: 2rem;
        width: 90%; /* Restrict to 60% width */
        margin-left: 1rem;
        padding-bottom: 0;
        -webkit-text-stroke-width: .3rem;

        
    }
    
    .featuredDesc {
        width: 90%; /* Restrict to 60% width */
        margin-left: 1rem; /* Add left margin */

    }

  
  
  .sitebodyContainer {
      padding: 0;
      margin: 0;
  }
  
  .intro {

  }
  
}

@media (prefers-color-scheme: dark) {
    
    .homeUI {
        background-color: var(--color-dark-background);
    
    }
    
    /* Left gradient fade */
    .featuredBoardsContainer::before {
        left: 0;
        background: linear-gradient(to right, var(--color-dark-background) 0%, transparent 100%);
    }

    /* Right gradient fade */
    .featuredBoardsContainer::after {
        right: 0;
        background: linear-gradient(to left, var(--color-dark-background) 0%, transparent 100%);
    }
    
    
}
