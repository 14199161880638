@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');


.sidecar-container {
  display: flex;
  flex-basis: 33%;
  transition: all 0.3s ease;
  justify-content: flex-end;
    position: relative; /* Ensure the sidecar respects normal flow */
    z-index: 1; /* Lower z-index so it doesn't overlap the board */
  }


.sidecar-left-column {
  width: 3rem; /* Adjust based on your desired width */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.sidecar-content {
  transitsion: all 0.5s ease;
    display: flex;
    flex-direction: column;
  }

.toggle-icon {
  cursor: pointer;

}

.sidecar-container.closed .sidecar-content {
  transform: translateX(100%); /* Slide content out of view */
  
}

.sidecar-container.closed .sidecar-left-column {
  transform: translateX(17vw); /* Slide content out of view */
}



/* Rows inside the second column */
.row {
  background-color: var(--color-card);
  border-radius: 15px;
  padding: 2.25rem;
}


.row-1, .row-2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allows rows to grow equally based on content */
  flex-shrink: 1; /* Allows rows to shrink equally based on container size */
}

.row-2 {
  max-height: 75%; /* Ensures row-2 does not exceed 75% height */
  overflow: auto; /* Adds scroll if content overflows within max height */
  margin-top:3rem;
}

h2, p {
  text-align: center;
}

h2 {
  font-size: 1.5rem;
  font-weight: 1200;
  margin-bottom: .5rem;
}

p {
    font-weight: 600;
    
}

.sidecar-description{
    padding: 0;
    margin-top: .5rem;
    
}

.scrollable-suggestions {
  flex: 1; /* Fill remaining space in row-2 */
  overflow-y: scroll; /* Enable vertical scrolling */
  margin-top: 1rem;
  overflow-x: hidden;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }

  .sidecar-content .suggestion-item {
    display: flex;
    justify-content: space-between; /* Space between content and suggestion details */
    align-items: center; /* Vertically center items */
    margin-bottom: .5rem; /* Space between suggestions */
    padding: 1rem;
  border-radius: 15px;
}

.sidecar-content .suggestion-content {
  text-align: left;
  flex-grow: 1; /* Ensure suggestion content expands */
  flex-wrap: wrap;
  align-content: center;
}

.sidecar-content .suggestion-text {
    display: block; /* Ensure the suggestion text occupies its own line */
    text-align: left; /* Align text to the left */
    font-family: "DM Mono";
}

.sidecar-content .suggestion-details {
  display: flex;
  flex-direction: column; /* Stack author and like count vertically */
  align-items: flex-end; /* Align items to the right */
  font-family: "DM Mono";
}

.sidecar-content .suggestion-author {
  font-size: 0.9rem; /* Smaller font size for the author text */
  color: #888; /* Lighter color for the author text */
  text-align: left; /* Align text to the left */
  font-family: "DM Mono";
  width: 100%;
  margin-bottom: 0;

}

.sidecar-content  .like-count {
    display: flex;
    align-items: center; /* Center the like count and heart vertically */
  }

.sidecar-content .like-count img {
  margin-left: 1.5rem; /* Space between like count and heart */
}

.sidecar-content .new-suggestion-input {
  display: flex;
  align-items: center; /* Aligns content vertically center */
  justify-content: space-between; /* Ensures even spacing between items */
  padding: .5rem 1rem; /* Matches the padding of suggestion items */
  margin-top: 1rem;
  margin-bottom: 1rem; /* Similar spacing below the input */
  border-radius: 15px; /* Rounded corners for consistency */
  background-color: var(--color-neutral-primary);
  box-sizing: border-box; /* Ensures padding is included in total width */
  width: 100%; /* Full width within the container */
}

.sidecar-content .new-suggestion-input input {
  height: 20px; /* Smaller height when not focused */
  transition: height 0.3s ease, border-color 0.3s ease; /* Smooth transition for height and border color */
  border: none; /* Remove default border */
  border-bottom: 0px solid #ccc; /* Add underline */
  background: transparent; /* Transparent background for plain text look */
  color: inherit; /* Match text color with surrounding content */
  padding: 0.5rem 0; /* Vertical padding for spacing */
  width: 100%; /* Full width to match nearby elements */
  outline: none; /* Remove default outline */
  font-family: "DM Mono";

}

.sidecar-content .new-suggestion-input input:focus {
  height: 40px; /* Larger height when focused */
  border-bottom: 1px solid #ccc; /* Add underline */
  margin-bottom: .5rem;
}

.sidecar-content .new-suggestion-input button {
  display: inline-block; /* Always show the button */
  background: none; /* Remove button background */
  border: none; /* Remove button border */
  color: inherit; /* Match text color */
  cursor: pointer; /* Pointer cursor for interactivity */
  padding: 0.5rem; /* Padding for consistent spacing */
  outline: none; /* Remove default outline */
  font-family: "DM Mono";
  transition: color 0.3s ease; /* Smooth transition for focus effect */
  color: #555;
}

/* Optionally, adjust styles based on input focus */
.sidecar-content .new-suggestion-input input:focus + button {
  /* Styles for when the input is focused */
  color: #888; /* Example color change */
}

.sidecar-content .new-suggestion-input button:hover,
.sidecar-content .new-suggestion-input button:focus {
  color: #888; /* Slightly brighter text color on hover/focus */
}


@media (prefers-color-scheme: dark) {
    
    .row {
      background-color: var(--color-dark-card); /* Dark background color */
      color: white;
    }
    
    .toggle-icon {
        content: url('../assets/bar-dark.svg'); /* Dark version of the icon */
      }
    
    .toggle-icon {
        content: url('../assets/bar-dark.svg'); /* Dark version of the icon */
      }
    
    .like-count .heart-outline {
        content: url('../assets/heart-dark.svg'); /* Dark version of the heart outline */
      }
    
    .sidecar-content .new-suggestion-input
    {
        background-color: var(--color-dark-card); /* Dark background color */
    }
}

@media (max-width: 1000px) {
    .column-2 {
      width: 100%; /* Ensures it takes up full width on small screens */
      margin-top: 1rem; /* Adds spacing between the stacked columns */
    }

    .sidecar-container {
      width: 100%; /* Ensure the sidecar container also becomes full width */
    }
    
    .sidecar-left-column {
        display: none;
    }
    
    .row {
        
    }
    

}
