.searchContainer {
  text-align: center;
  width: 85vw;
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
}

.searchTitle {
    margin: 1em;
}

.searchInput {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.resultsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.resultTile {
  border-radius: 10px;
  background-color: #f9f9f9;
}


.newBoardTile {
  background-color: var(--color-background);
  font-weight: bold;
    border-radius: 10px;
    border: 3px dashed var(--color-stroke);
  }


.searchContainer .boardSection {
  position: relative;
  width: 100%;
  height: 20vh;
  border-radius: 15px;
  background-size: cover;
  background-position: center top;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.searchContainer .boardSection::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  backdrop-filter: blur(50px);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.searchContainer .boardTitle {
  position: relative;
  z-index: 2;
  color: white;
  font-size: 1.2rem;
  padding: 10px;
  text-align: center;
}

@media (prefers-color-scheme: dark) {
    
    .newBoardTile {
      background-color: var(--color-dark-background);
      }
    
    border: 3px dashed var(--color-dark-stroke);


}
