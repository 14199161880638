/* Main container adjustments */
.bingo-square .refresh-icon-image{
display: none;
}

/* Main container adjustments */
.scrollfixer {
  padding: 0;
  margin: 0;
}

.mainUI {
  display: flex;
  flex-grow: 1;
  height: 92vh;
  transition: all 0.3s ease;
  padding: 2rem;
  padding-top: 0;
  background-color: var(--color-background);
}

.mainUI.shift-right {
  transform: translateX(15%);
}

.scrollfixer.enabled {
  overflow: hidden;
}

/* Column styles */
.column-1 {
  position: relative;
  flex-grow: 1;
  width: 66%;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1em;
}

/* Blurred background that fills column-1 */
.blurred-bg {
  position: absolute;
  top: -5%;
  left: -5%;
  height: 110%;
  width: 110%;
  background-size: cover;
  background-position: center;
  filter: blur(100px);
  z-index: 0;
  pointer-events: none;
}

.title-section {
  z-index: 1;
  width: 100%;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center; /* Center the header text vertically */
  justify-content: center; /* Center horizontally */
  position: relative; /* Position the icon inside the title section */
}


.refresh-container {
  position: absolute;
  right: 0; /* Space between the title and the icon */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  width: 6vw;
}

.refresh-icon {
  width: 100%;
  height: 90%; /* Make the icon height match the height of the header */
  max-height: 90%; /* Ensure it doesn't exceed the header's height */
  cursor: pointer;
  fill: var(--color-card); /* Change the fill color */
  stroke: var(--color-dark-neutral-primary); /* Add stroke */
  stroke-width: 20px; /* Stroke thickness */
  paint-order: stroke fill;
  box-sizing: border-box;
}

/* Bingo board wrapper with rounded corners */
.bingo-board-wrapper {
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;

}

/* Bingo board grid */
.bingo-board-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* Ensure padding doesn't affect overall size */
}

/* Make the 1st, 3rd, and 5th columns 5% darker */
.bingo-board-grid .bingo-square:nth-child(5n+2),  /* 1st column */
.bingo-board-grid .bingo-square:nth-child(5n+4) {  /* 5th column */
  background-color: rgba(235, 229, 227, 1); /* 5% darker effect */
  border: 8px solid rgba(235, 229, 227, 1);
  
}

.free-space-overlay-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  position: absolute;
  margin: 1em;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensure clicks pass through to the board below */
}

/* Free space tile styling */
.free-space-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  visibility: hidden; /* Default all tiles are invisible */
}

/* Styling for the center free space tile */
.free-space-center {
  visibility: visible;
  border: 15px solid var(--color-card);
  background-color: rgba(0, 0, 0, 0.5); /* Adjust to your preference */
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 25px;
}

/* Bingo square tiles */
.bingo-square {
  background-color: var(--color-card);
  display: flex;
  border: 8px solid var(--color-card);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box; /* Ensure padding doesn't affect overall size */

}

.bingo-square:nth-of-type(13) {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    border: .5px solid var(--color-card);
    
}

/* Free space overlay */
.free-space-overlay {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  z-index: 2;
}

.suggestion-content {
  flex-grow: 1; /* Allows it to grow and pushes author name down */
  display: flex;
  align-items: center; /* Centers content vertically within itself */
}

.bingo-square .suggestion-author {
  margin-top: auto; /* Pushes author name to the bottom */
  position: absolute;
  bottom: -1rem; /* Adjust as needed */
  font-size: 0.8rem;
  color: var(--color-dark-neutral-secondary);
  font-family: "DM Mono";
  width: 85%;
  padding-bottom: 5px;

}


@media (prefers-color-scheme: dark) {
    
    .mainUI {
        background-color: var(--color-dark-background);

    }
    
  
  .free-space-center {
    border: 15px solid var(--color-dark-card);
  }

  /* Bingo square tiles */
  .bingo-square {
      background-color: var(--color-dark-card);
      border: 8px solid var(--color-dark-card);
  }
  
  .bingo-square:nth-of-type(13) {
      border: .5px solid var(--color-dark-card);
      
  }
  
  .bingo-square .suggestion-author {
    color: var(--color-neutral-secondary);
  }
  
  .refresh-icon {
   
    fill: var(--color-dark-card); /* Change the fill color */
    stroke: var(--color-neutral-primary); /* Add stroke */
  }
  
  .bingo-board-grid .bingo-square:nth-child(5n+2),  /* 1st column */
  .bingo-board-grid .bingo-square:nth-child(5n+4) {  /* 5th column */
    background-color: rgba(49, 47, 45, 1); /* 5% darker effect */
    border: 3px solid rgba(49, 47, 45, 1);
    
  }
}


@media (max-width: 1200px) {
  /* Main UI container */
  .mainUI {
    flex-direction: column;
    height: auto;
    padding: 0.3rem;
  }

  /* Column 1 adjustments */
  .column-1 {
    width: 96%;
    height: 85vh;
    margin-bottom: .5rem;
    padding: 0 .5em;
  }

  /* Bingo board wrapper */
  .bingo-board-wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-bottom: .5em;
  }

  /* Bingo header margin */
  .bingoHeader {
    margin: 0;
    margin-top: .5rem;
  }

  /* Bingo squares border adjustment */
  .bingo-square {
    border: 2px solid var(--color-card);
  }
  
  .bingo-board-grid .bingo-square:nth-child(5n+2),  /* 1st column */
  .bingo-board-grid .bingo-square:nth-child(5n+4) {  /* 5th column */
    background-color: rgba(235, 229, 227, 1); /* 5% darker effect */
    border: 3px solid rgba(235, 229, 227, 1);
    
  }

  /* Free space overlay grid adjustments */
  .free-space-overlay-grid {
    margin: 0em;
  }

  /* Free space tile styling */
  .free-space-tile {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    visibility: hidden; /* Default all tiles are invisible */
  }

  /* Styling for the center free space tile */
  .free-space-center {
    visibility: visible;
    border: 5px solid var(--color-card);
    background-color: rgba(0, 0, 0, 0.0); /* Adjust to your preference */
    border-radius: 15px;
  }
  
  .suggestion-content {
      padding-bottom: 5px;
  }

  /* Suggestion author adjustments */
  .bingo-square .suggestion-author {
    margin-top: auto; /* Pushes author name to the bottom */
    position: absolute;
    bottom: -10px; /* Adjust as needed */
    font-size: 0.5rem;
    line-height: .5rem;
    color: var(--color-neutral-secondary);
    font-family: "DM Mono";
    width: 95%;
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides any overflow text */
    text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
  }

  /* Refresh icon adjustment */
  .refreshIcon {
    position: absolute;
    top: .5rem;
    right: .5rem;
    cursor: pointer;
    width: 20px;
  }

  /* Adjust font size for bingo square */
  .bingo-square {
    padding: 0px 5px;
    font-size: min(2.8vw, 3rem);
  }
}


@media (prefers-color-scheme: dark) and (max-width: 768px) {
  /* CSS rules for dark mode and smaller screen widths (e.g., 768px) */
  /* Bingo squares border adjustment */
  .bingo-square {
    border: 2px solid var(--color-dark-card);
  }
  
  .bingo-board-grid .bingo-square:nth-child(5n+2),  /* 1st column */
  .bingo-board-grid .bingo-square:nth-child(5n+4) {  /* 5th column */
    background-color: rgba(49, 47, 45, 1); /* 5% darker effect */
    border: 3px solid rgba(49, 47, 45, 1);
    
  }

  .free-space-center {
    visibility: visible;
    border: 5px solid var(--color-dark-card);
    background-color: rgba(0, 0, 0, 0.0); /* Adjust to your preference */
    border-radius: 15px;
  }

  /* Any other styles specific to dark mode on small screens */
}
