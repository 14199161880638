.createBoardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.inputSection, .choiceSection, .reviewSection {
  width: 75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

input, textarea, select {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
}

.choiceSection div {
  cursor: pointer;
  padding: 1rem;
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  text-align: center;
  width: 50%;
}

button {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
}
